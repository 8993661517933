@import "sass-lib";


@media(min-width: 1px) and (max-width: 860px){
	h5{font-size: 14px;}
	p{line-height: 24px;}

	.wrapper{width: 100%; padding: 0 40px; @include boxSizing(border-box);} 
	header.head-log .wrapper, .box-login .wrapper{width: 100%; padding: 0 40px;@include boxSizing(border-box);}
	.wrap-inner{width: 542px; max-width: 90%;}

	.btn-oval {
		&.abs {position: relative; top:0; text-align: center;}  
	}


	.row_list{
        .col{
            &.col_sml_full{width: 100%; display: block;}  
            &.col_sml_8{width: 80%;}
            &.col_sml_7{width: 70%;}
            &.col_sml_66{width: 66.66666666666666%;}
            &.col_sml_6{width: 60%;}
            &.col_sml_55{width: 55%;}
            &.col_sml_5{width: 50%;}
            &.col_sml_45{width: 45%;}
            &.col_sml_4{width: 40%;}
            &.col_sml_35{width: 35%;}
            &.col_sml_33{width: 33.3333333333333%;}
            &.col_sml_3{width: 30%;}
            &.col_sml_25{width: 25%;}
            &.col_sml_2{width: 20%;}
            &.col_sml_16{width: 16.66666666666666%;} 
            &.col_sml_1{width: 10%;}
        }

        &.lsborder {
	        border-bottom: 1px solid #ccc; margin-bottom: 40px;        
	        .col {
	            padding-left:15px; padding-right: 15px; position: relative;        
	        }
	        &.form {
	            padding:0 0px 50px 0px; 
	            .col {
	                padding-left: 20px; padding-right: 20px;
	            }
	        }
	        &.last {border-bottom: 0;} 
	    }
    }

	header{
		.header-bottom{padding: 20px 0;}
		.logo{width: 140px;}
		.nav-form-header{
			margin-top: 35px;
			li{
				padding: 0 15px;
				a{width: 12px; height: 12px;}
				&:first-child{padding-right: 15px;}
				&:last-child{padding-left: 15px;}
			}
		}
		&.head-log{
			.logo{
				width: 100%; text-align: center;
				img{width: 165px;}
			}
			.inner-head-log{border-bottom: 1px solid #355d88;}
		}
	}

	.box-login{
		padding-bottom: 50px;
		.text-left{width: 100%; height: auto; padding: 115px 0 90px; text-align: center;}
		.box-right-log{position: relative; top: auto; width: 325px; right: auto; max-width: 100%; margin: 0 auto;}
	}

	.box-status-estimasi{
		h5{margin-bottom: 10px;}
		.left-estimasi{width: 100%; position: relative; top: auto; left: auto; bottom: auto; @include borderRadius(10px 10px 0 0); padding: 50px 40px;}
		.right-estimasi{width: 100%; padding: 42px 40px;}
	}
	.slidetitle{
		.box-slidetitle{
			.list-slidetitle{min-width: 75px;}
		}
	}

	.popup{
		.inner-popup{width: 570px;}
		.wrap-popup{width: 100%; padding: 0 30px; @include boxSizing(border-box);}
	}
	.box-otp-code{width: 300px;}
	

	header {
		.header-bottom {
			&.adm {
				.nav_mn {margin-top: 35px;}
			}
		}
	}

	.wrap-inner {
		&.wrap_admin {
			width: 740px;
		}
		&.full {
	        width: auto;
	        .filter {
	        	margin: 0 0 20px;
	            .left {
	                input[type="text"] {width: 270px;}
	                @include placeholder {color: #999;}
	            }
	            input[type="text"] {width: 200px;}
	        }
	        .content_table {
	            overflow-y: auto;
	            table {
	            	th,td {
	                    font-size: 14px;
	                    &:last-child {border-right: 1px solid #d8d8d8;}
	                    &:first-child {border-left: 1px solid #d8d8d8;}
	                }
	            }
	        }
	    }
	}

	.table-std { 
		tr {
			td {
				.tb-btn {
					float: none; display: inline-block;     
					&.update {

					}
				}
			}
		}
	}
	.table-std3 {
		table {
			tr {
				td {
					padding: 5px 10px;
					&:first-child {padding-top: 15px; padding-left: 15px;}    
				}
				&:first-child {
					td {padding-left:15px; padding-top: 15px;} 
				}
			}
		}
	}
	.table-responsive {   
		thead {display: none;}  		
		tr {
			td {
				display: block; position: relative; border-right: 0;   
				padding: 15px 10px 5px 10px; border:0; border-bottom: 0px solid #ccc;  
				&:first-child {font-size:14px; text-transform: uppercase;}          
				&:nth-child(1n+1) { 				
					&:before {
						content: attr(data-title) ""; font-weight: bold;   
						position: relative; width: 100%; left: 0; top: 0;       
						display: block; padding-bottom: 10px; font-size:14px; color: #333;   
					}
				}
				&:last-child {padding-bottom:30px; border-bottom: 1px solid #ccc;} 
			}		
			&:last-child { 
				td {}  
			}	
		}
	}


}